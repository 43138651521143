<template>
<div v-if="this.load == true" class="user-panel">
    <img class="user-panel__avatar" src="@/assets/user.png">
    <div>
        <div class="user-panel__name">{{this.user.name}}</div>
        <div class="user-panel__place">{{this.user.place}}</div>
    </div>
</div>
<div v-else style="width: 100%; text-align: center; margin: 15px 0px;">
    <div class="spinner-border text-secondary" style="text-align: center;"></div>
</div>
</template>

<script>
export default {
    data(){
        return {
            user: {
                name: '',
                place: ''
            },
            load: false
        }
    },
    beforeCreate(){
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + this.$store.getters.getAuthKey);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(this.$store.state.app.apiHost + "/api/v1/partners/get-profile", requestOptions)
        .then(response => {
            if(response.ok){
                response.json().then((response) => {
                    this.user.name = response.name
                    this.user.place = response.place
                    this.load = true
                })
            }
        })
        .catch(error => console.log('error', error));
    }
}
</script>

<style>

</style>