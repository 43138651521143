<template>
<div class="container">
	<div class="row">
		<div class="col-12">
			<UserInfo></UserInfo>
		</div>
	</div>
	<div class="row">
		<div class="col-12">
			<router-link to="/add-code" class="add-code-btn">
				<img src="../assets/icons/plus.png">
				<div>Добавить промокод</div>
			</router-link>
		</div>
	</div>
	<div class="row menu">
		<div class="col-6 menu-card-wr">
			<router-link to="/promocodes" class="menu-card">
				<img src="../assets/icons/promocode.png">
				<div>Мои промокоды</div>
			</router-link>
		</div>
		<div class="col-6 menu-card-wr">
			<router-link to="/account" class="menu-card">
				<img src="../assets/icons/user.png">
				<div>Мои данные</div>
			</router-link>
		</div>
		<div class="col-6 menu-card-wr">
			<router-link to="/about" class="menu-card">
				<img src="../assets/icons/question.png">
				<div>Как пользоваться приложением?</div>
			</router-link>
		</div>
		<div class="col-6 menu-card-wr">
			<router-link to="/support" class="menu-card">
				<img src="../assets/icons/message.png">
				<div>Поддержка</div>
			</router-link>
		</div>
		<div class="col-6 menu-card-wr">
			<router-link to="/page/oferta" class="menu-card">
				<img src="../assets/icons/message.png">
				<div>Договор оферты</div>
			</router-link>
		</div>
		<div class="col-6 menu-card-wr">
			<router-link to="/page/pravila" class="menu-card">
				<img src="../assets/icons/message.png">
				<div>Правила использования промокодов</div>
			</router-link>
		</div>
		<div class="col-6 menu-card-wr">
			<div class="menu-card" @click="this.$store.commit('logout')">
				<img src="../assets/icons/exit.png">
				<div>Сменить аккаунт</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import HelloWorld from '@/components/HelloWorld.vue'
import UserInfo from '@/components/Home/UserInfo.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld, UserInfo
  }
}
</script>
