<template>
	<div class="container">
		<div class="row main-header" v-if="$route.path != '/auth' && $route.path != '/register'">
			<div class="col-4" style="text-align: left;"><img class="main-header__icon" @click="$router.back()" src="./assets/icons/back.png"></div>
			<div class="col-4" style="text-align: center;"><router-link to="/"><img class="main-header__logo" src="./assets/logo.png"></router-link></div>
			<div class="col-4" style="text-align: right;"><router-link to="/about"><img class="main-header__icon" src="./assets/icons/question.png"></router-link></div>
		</div>
		<div class="row" v-else>
			<div class="col-12" style="text-align: center; margin: 40px 0px 20px 0px;">
				<router-link to="/"><img class="big-logo" src="./assets/logo.png"></router-link>
			</div>
		</div>
	</div>
	<transition name="fade2">
		<router-view/>
	</transition>
</template>

<script>
</script>


<style>

</style>
