import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index.js'
import CookieService from '../services/CookieService.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {auth: true}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  },
  {
    path: '/promocodes',
    name: 'promocodes',
    component: function () {
      return import('../views/PromocodesView.vue')
    },
    meta: {auth: true}
  },
  {
    path: '/add-code',
    name: 'add-code',
    component: function () {
      return import('../views/AddCodeView.vue')
    },
    meta: {auth: true}
  },
  {
    path: '/register',
    name: 'register',
    component: function () {
      return import('../views/RegistrationView.vue')
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: function () {
      return import('../views/AuthView.vue')
    }
  },
  {
    path: '/account',
    name: 'account',
    component: function () {
      return import('../views/AccountView.vue')
    },
    meta: {auth: true}
  },
  {
    path: '/password-reset',
    name: 'password-resset',
    component: function () {
      return import('../views/PasswordResetView.vue')
    },
    meta: {auth: true}
  },
  {
    path: '/support',
    name: 'support',
    component: function () {
      return import('../views/SupportView.vue')
    },
    meta: {auth: true}
  },
  {
    path: '/page/:slug',
    name: 'page',
    component: function () {
      return import('../views/PageView.vue')
    },
    meta: {auth: false}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isAuth = to.matched.some(record => record.meta.auth);
  const authKey = store.state.user.authKey;
  const currentRoute = router.currentRoute._value.path;

  if(isAuth && authKey == null){

    // let token = CookieService.getCookie('__bm')
    let token = localStorage.getItem('__bm')

    if(token){
      var formdata = new FormData();
      formdata.append('token', token);
  
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
  
      fetch(store.state.app.apiHost + "/api/v1/auth/check-token", requestOptions)
      .then(response => {
        if(response.ok){
          store.commit('setAuthKey', token)
          next();
        }else{
          next('/auth');
        }
      })
      .catch(error => console.log('error', error));
    }else{
      next('/auth');
    }
  }else{
    next();
  }
});

export default router
