import { createStore } from 'vuex'
import router from '../router/index.js'
import CookieService from '../services/CookieService.js'

const moduleUser = {
	state: {
		authKey: null,
	},
	getters: {
		getAuthKey(state){
			return state.authKey;
		}
	},
	mutations: {
		setAuthKey(state, key){
			state.authKey = key
		},
		logout(state){
			state.authKey = null
			// CookieService.deleteCookie('__bm')
			localStorage.removeItem('__bm')
			router.push('/auth')
		}
	},
	actions: {
	}
}

const moduleLayout = {
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	}
}


const modulePromocodes = {
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	}
}

const moduleApp = {
	state: {
		apiHost: 'https://belmebel-partners.ru'
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	}
}

export default createStore({
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		user: moduleUser,
		promocodes: modulePromocodes,
		layout: moduleLayout,
		app: moduleApp
	}
})
